import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class ShareService {
  appComp:any;
  mobile:boolean;
  loginObj:any;
  floor_obj:any;
  block0_obj:any;
  dynamic_obj:any;
  block1_obj:any;
  block2_obj:any;
  block3_obj:any;
  floor_group_obj:any;
  floor_subgroup_obj:any;
  unit_obj:any;
  unit_group_obj: any;
  unit_subgroup_obj: any;
  main_side_nav:any;
  // landing_obj:any;
  main_property_obj:any;
  heightService: number;
  sideNav_obj:any;
  floor_map_obj:any;
  toolbox_obj: any;
  toolbox_dialog_obj:any;
  floorMapDialogObj:any;
  header_obj:any;
  selected_obj_uid:string;
  public loader = new BehaviorSubject<any>(true);
  public geometry_loader = new BehaviorSubject<any>(false);
  public selectedUnitId = new Subject<any>();

  public showTanent = new BehaviorSubject<any>('true');
  private updateStateSubject = new BehaviorSubject<boolean>(false);

  updateState$: Observable<boolean> = this.updateStateSubject.asObservable(); // Observable for components to subscribe

  // Method to update the
  updateCurrentState(updateState: boolean): void {
    this.updateStateSubject.next(updateState);
  }

  // Method to get the current
  getCurrentState(): boolean {
    return this.updateStateSubject.getValue();
  }
  constructor() { }
  addPushSubscriber(sub){

  }
}
