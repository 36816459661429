<div *ngIf="!mobile" class="rel">
  <div  [ngClass]="{'full-view':full_view}">
    <mat-toolbar class="tool">Floor Map - {{selected_floor.uid}} </mat-toolbar>
    <!-- <div #floorSwitcherContainer class="hidden"></div> -->
    <div class="drag-boundary">
    <div cdkDrag cdkDragBoundary=".drag-boundary" cdkDrag class="drag-wrapper" [cdkDragFreeDragPosition]="dragPosition">
        <toolbox-map [selected_unit_uid]="selected_unit_uid" class="toolbox-web" cdkDragHandle></toolbox-map>
    </div>
    <button class="full-view-btn" (click)="openFullView()">
        <svg *ngIf="!full_view" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 384 384" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M373.332 0H288c-5.89 0-10.668 4.777-10.668 10.668S282.109 21.332 288 21.332h59.582l-88.457 88.461a10.67 10.67 0 0 0-2.91 10.371 10.672 10.672 0 0 0 7.62 7.621 10.67 10.67 0 0 0 10.372-2.91l88.461-88.457V96c0 5.89 4.773 10.668 10.664 10.668S384 101.891 384 96V10.668C384 4.778 379.223 0 373.332 0zM109.793 259.125l-88.461 88.457V288c0-5.89-4.773-10.668-10.664-10.668S0 282.109 0 288v85.332C0 379.222 4.777 384 10.668 384H96c5.89 0 10.668-4.777 10.668-10.668S101.891 362.668 96 362.668H36.418l88.457-88.461c4.043-4.184 3.984-10.836-.129-14.953-4.117-4.113-10.77-4.172-14.953-.129zM373.332 277.332c-5.89 0-10.664 4.777-10.664 10.668v59.582l-88.461-88.457c-4.184-4.043-10.836-3.984-14.953.129-4.113 4.117-4.172 10.77-.129 14.953l88.457 88.461H288c-5.89 0-10.668 4.773-10.668 10.664S282.109 384 288 384h85.332c5.89 0 10.668-4.777 10.668-10.668V288c0-5.89-4.777-10.668-10.668-10.668zM36.418 21.332H96c5.89 0 10.668-4.773 10.668-10.664S101.891 0 96 0H10.668C4.778 0 0 4.777 0 10.668V96c0 5.89 4.777 10.668 10.668 10.668S21.332 101.891 21.332 96V36.418l88.461 88.457c4.184 4.043 10.836 3.984 14.953-.129 4.113-4.117 4.172-10.77.129-14.953zm0 0" fill="#939393" data-original="#000000"></path></g></svg>
        <svg *ngIf="full_view" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 384.262 384.262" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M266.797 128.133h85.336c5.89 0 10.664-4.778 10.664-10.668s-4.774-10.668-10.664-10.668h-59.586l88.457-88.457A10.668 10.668 0 0 0 376.297.348a10.668 10.668 0 0 0-10.375 2.91l-88.457 88.457V32.133c0-5.895-4.778-10.668-10.668-10.668s-10.664 4.773-10.664 10.668v85.332c0 5.89 4.773 10.668 10.664 10.668zM117.465 256.133H32.133c-5.895 0-10.668 4.773-10.668 10.664s4.773 10.668 10.668 10.668h59.582L3.258 365.922a10.668 10.668 0 0 0 4.707 17.992c3.73.977 7.695-.137 10.375-2.91l88.457-88.457v59.586c0 5.89 4.777 10.664 10.668 10.664s10.668-4.774 10.668-10.664v-85.336c0-5.89-4.778-10.664-10.668-10.664zM292.547 277.465h59.586c5.89 0 10.664-4.777 10.664-10.668s-4.774-10.664-10.664-10.664h-85.336c-5.89 0-10.664 4.773-10.664 10.664v85.336c0 5.89 4.773 10.664 10.664 10.664s10.668-4.774 10.668-10.664v-59.586l88.457 88.457c4.187 4.043 10.84 3.988 14.953-.129 4.117-4.113 4.172-10.766.129-14.953zM117.465 21.465c-5.89 0-10.668 4.773-10.668 10.668v59.582L18.34 3.258C14.152-.785 7.5-.727 3.387 3.387s-4.172 10.765-.13 14.953l88.458 88.457H32.133c-5.895 0-10.668 4.777-10.668 10.668s4.773 10.668 10.668 10.668h85.332c5.89 0 10.668-4.778 10.668-10.668V32.133c0-5.895-4.778-10.668-10.668-10.668zm0 0" fill="#939393" data-original="#000000" class=""></path></g></svg>
    </button>
    <div #map class="map-web"> </div>
    <div class="btn-next">
        <div id="nav-btn">
            <button mat-button class="editBtn" (click)="unitEdit()" [disabled]="isAttributeLoading">
                Edit
                <mat-icon class="forward">edit</mat-icon>

            </button>
            <button (click)="redirectToGroup()" mat-button class="nxtBtn" [disabled]="isAttributeLoading">
                Next
                <mat-icon class="forward">arrow_forward</mat-icon>
            </button>
        </div>
    </div>
    <ngx-loading [show]="(loader || map_loader) && !isAttributeLoading" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.0)'}"></ngx-loading>
</div>
</div>
</div>
<ngx-loading [show]="isAttributeLoading" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.0)'}"></ngx-loading>
<div *ngIf="mobile">
    <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.0)'}"></ngx-loading>
    <app-mobile-toolbar [back]="true" [title]="'Floor Map'" [property_uid]="selected_property.property_id"  [customClickAction]="true" [customObj]="this" [floor_name]="selected_floor.name" [selected_entity]="selected_floor" [refreshView]="true"></app-mobile-toolbar>

    <!-- <div #floorSwitcherContainer id="floorSwitcherContainer"></div> -->
    <div [ng2-draggable]="true" *ngIf="reposition">
        <toolbox-map class="toolbox" [selected_unit_uid]="selected_unit_uid"></toolbox-map>
    </div>
    <div class="rel">
        <div #map id="map"></div>
    </div>
    <div class="nav-btn-mobile">
        <div id="nav-btn">
            <div class="cent">
                <button mat-button class="editBtn" (click)="unitEdit()" [disabled]="isAttributeLoading">
                    Edit
                    <mat-icon class="forward">edit</mat-icon>
                </button>
                <button (click)="redirectToGroup()" mat-button class="nxtBtn" [disabled]="isAttributeLoading">
                    Next
                    <mat-icon class="forward">arrow_forward</mat-icon>
                </button>
            </div>

        </div>
    </div>
</div>
