import { DatePipe, Location, PlatformLocation } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { debounceTime } from 'rxjs';
import { ApiserviceService } from '../../../services/apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { NotificationService } from '../../../services/notification.service';
import { ShareService } from '../../../services/share.service';
// import { IMultiSelectOption, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatIconRegistry } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { YEAR_MODE_FORMATS } from '../../quick-edit/quick-edit.component';
import { MasterMultiselect } from './master-multiselect';



export interface customizeAttributeList {
  id: number,
  name: string,
  label: string,
  data_type: string,
  measure_type: string,
  measure_unit: string,
  default_value: string,
  is_mandatory: boolean,
  is_hidden: boolean,
  is_editable: boolean,
  is_lang_dependent,
  status: boolean,
  master_id: number,
  has_linked_master: boolean,
  dependent: Array<any>,
  mutual: Array<any>,
  child: Array<any>
}

 /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_assignment_name_properties_property_id_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_get</li>
 * <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_get</li>
 * <li>estapi_entity_masters_master_id_values_post</li>
 * <li>estapi_attribute_measure_unit_type_measureunits_get</li>
 * <li>estapi_attribute_measureunits_get</li>
 * <li>estapi_entity_masters_master_id_values_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-block3',
  templateUrl: './block3.component.html',
  styleUrls: ['./block3.component.scss'],
  providers: [DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },]
})
export class Block3Component implements OnInit {
  globalLoader = {
    ldr1: false,
    ldr2: false,
    ldr3: false,
    ldr5: false,
    ldr6: false
  }
  mainLoader: boolean = false;
  //variables for search in mat select
  searchTerm: string;
  searchTerm1: string;
  newlist: any = [];
  selectedAttrId: any;
  addOtherOption: boolean = false;
  confirmation: boolean = false;
  loader1: boolean = false;
  //variables for search in mat select
  mobile: boolean = false;
  is_valid = false;
  dHeight: number;
  selected_property: any;
  selected_group: any;
  selected_sub_group: any;
  attribute_list: any;
  group_id: number;
  group_name: string;
  group_attribute: any = [];
  used_attribute: any = [];
  customize_attribute: Array<customizeAttributeList>;
  masters: any = [];
  master_val = [];
  measure_unit_type: any = [];
  years = [];
  form_language: any;
  language_list: any = [];
  resource_access: any;
  linked_master: any = [];
  active_field: any = {
    id: null,
    label: null
  };
  month = [
    {
      "id": 1,
      "abbreviation": "Jan",
      "name": "January"
    },
    {
      "id": 2,
      "abbreviation": "Feb",
      "name": "February"
    },
    {
      "id": 3,
      "abbreviation": "Mar",
      "name": "March"
    },
    {
      "id": 4,
      "abbreviation": "Apr",
      "name": "April"
    },
    {
      "id": 5,
      "abbreviation": "May",
      "name": "May"
    },
    {
      "id": 6,
      "abbreviation": "Jun",
      "name": "June"
    },
    {
      "id": 7,
      "abbreviation": "Jul",
      "name": "July"
    },
    {
      "id": 8,
      "abbreviation": "Aug",
      "name": "August"
    },
    {
      "id": 9,
      "abbreviation": "Sep",
      "name": "September"
    },
    {
      "id": 10,
      "abbreviation": "Oct",
      "name": "October"
    },
    {
      "id": 11,
      "abbreviation": "Nov",
      "name": "November"
    },
    {
      "id": 12,
      "abbreviation": "Dec",
      "name": "December"
    }
  ];



  // Settings configuration
  mySettings: any = {
    enableSearch: true,
    checkedStyle: 'checkboxes',
    buttonClasses: 'custom-sel',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false,
    // selectionLimit: 1,
    // autoUnselect: true,
  };
  // myText: IMultiSelectTexts = {
  //   checkAll: 'Select all',
  //   uncheckAll: 'Unselect all',
  //   checked: 'item selected',
  //   checkedPlural: 'items selected',
  //   searchPlaceholder: 'Search',
  //   searchEmptyResult: 'No results found...',
  //   searchNoRenderText: 'Type in search box to see results...',
  //   defaultTitle: 'Select',
  //   allSelected: 'All selected',
  // };
  selected_langauge: String = 'en';
  selected_Id: number;
  propertyForm: UntypedFormGroup;
  property_detail: any = null;
  is_submited = false;
  child_master_val: any = {};
  assignment_detail: any;
  loader: boolean = false;
  rejected_attribute: any;
  rejectmessage: string = '';
  searchMaster: UntypedFormControl;
  masterIDs: any = [];
  selectedValues: any = [];
  @Output() notif: EventEmitter<string> = new EventEmitter<string>();
  formLoaded: boolean;
  currentDate = new Date();
  max_date : string;
  min_date:string = "1970-01-01"
  constructor(public dialog: MatDialog, private share: ShareService, private route: Router,
    private api: ApiserviceService, private fb: UntypedFormBuilder, private _sanitizer: DomSanitizer,
    private fnc: CommonfunctionService, private location: Location, private elementRef: ElementRef,
    private notify: NotificationService, private plocation: PlatformLocation, private datePipe: DatePipe) {
    this.formLoaded = false;
    this.mobile = this.share.mobile;
    this.max_date = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    if (!this.share.block1_obj) {
      this.route.navigateByUrl('/');
      return;
    }
    //this.form_language = ((this.api.form_language) ? this.api.form_language : this.api.language) + '';
    this.form_language = this.api.language;

    if (this.api.language_list) {
      this.language_list = this.api.language_list;
    }
    this.assignment_detail = JSON.parse(localStorage.getItem('assignment_detail'));

    this.share.block3_obj = this;
    let date = new Date();
    let current_year = date.getFullYear();
    let start = current_year - 30;
    for (start; start < current_year + 30; start++) {
      this.years.push(start);
    }
    this.selected_property = this.share.block1_obj.selected_property;
    if (this.share.block0_obj.entity_type == "property") {
      this.selected_Id = this.share.block0_obj.selected_property.id;
      this.resource_access = this.share.block0_obj.property_access;
      this.selected_group = this.share.block1_obj.selected_group;
      this.group_id = this.selected_group.id;
      this.group_name = this.selected_group.group_name;
      if (this.share.block2_obj) {
        this.selected_sub_group = this.share.block2_obj.selected_sub_group;
        this.group_id = this.selected_sub_group.id;
        this.group_name = this.selected_sub_group.group_name;
      }

      this.attribute_list = this.fnc.getChildArray('group_id', this.group_id, this.share.block0_obj.attribute_custom_list);
      this.rejected_attribute = this.share.block0_obj.rejectedAttributeList;
    }
    if (this.share.block0_obj.entity_type == "property_floor") {
      this.selected_Id = this.share.floor_obj.selected_floor_id;
      this.resource_access = this.share.block0_obj.floor_access;
      this.selected_group = this.share.floor_group_obj.selected_group;
      this.group_id = this.selected_group.id;
      this.group_name = this.selected_group.group_name;
      if (this.share.floor_subgroup_obj) {
        this.selected_sub_group = this.share.floor_subgroup_obj.selected_sub_group;
        if (this.selected_sub_group) {
          this.group_id = this.selected_sub_group.id;
          this.group_name = this.selected_sub_group.group_name;
        }
      }

      this.attribute_list = this.fnc.getChildArray('group_id', this.group_id, this.share.block0_obj.attribute_custom_list_floor);

      this.rejected_attribute = this.share.floor_obj.rejectedFloorAttributeList;
    }
    if (this.share.block0_obj.entity_type == "property_unit") {
      this.selected_Id = this.share.unit_obj.selected_unit.id;
      this.resource_access = this.share.block0_obj.unit_access;
      this.selected_group = this.share.unit_group_obj.selected_group;
      this.group_id = this.selected_group.id;
      this.group_name = this.selected_group.group_name;
      if (this.share.unit_subgroup_obj) {
        this.selected_sub_group = this.share.unit_subgroup_obj.selected_sub_group;
        if (this.selected_sub_group) {
          this.group_id = this.selected_sub_group.id;
          this.group_name = this.selected_sub_group.group_name;
        }
      }

      this.attribute_list = this.fnc.getChildArray('group_id', this.group_id, this.share.block0_obj.attribute_custom_list_unit);
      this.rejected_attribute = this.share.unit_obj.rejectedUnitAttributeList;

    }
    this.attribute_list = JSON.parse(JSON.stringify(this.attribute_list));
    this.setPropertyDetail();
    this.freezAttribute();
    this.createFormGroup();
    this.propertyForm = this.createFormElement();
    this.getMeasureUnitType();
    this.share.block0_obj.getAllowResource();
    this.loadCheck();
  }


  formatDateaApi(value: any) {
    if(value){
      value = new Date(value);
      return (
        value.getFullYear() +
        '-' +
        (value.getMonth() + 1).toString().padStart(2, 0) +
        '-' +
        value.getDate().toString().padStart(2, 0)
      );
    }
    return null;
  }
  //Check if loading finished
  loadCheck() {
    let arr = [this.globalLoader.ldr1, this.globalLoader.ldr2, this.globalLoader.ldr3, this.globalLoader.ldr5, this.globalLoader.ldr6];
    let ctr = 0;
    arr.forEach((l) => {
      if (l == false) {
        ctr++;
      }
    })
    if (ctr == 5) {
      this.mainLoader = false;
    } else {
      this.mainLoader = true;
    }

  }


  //frezz all attribute in case of rejected
  freezAttribute() {
    let is_old = false;
    if (this.property_detail) {
      if (this.property_detail.status != 1) is_old = true;
    }
    if ((this.selected_property.status == 4 || this.selected_property.status == 6) && is_old) {
      this.attribute_list.forEach((e, k) => {
        e.is_editable = false;
        if (this.fnc.getArrayValue('keyId', e.id, this.rejected_attribute)) {
          e.is_editable = true;
        }
      });
    }
  }

  setPropertyDetail() {
    if (this.share.block0_obj.entity_type == 'property') {
      let property_detail = JSON.parse(localStorage.getItem('property_detail'));
      if (property_detail != null && this.form_language == this.api.language) {
        if (property_detail.property_id == this.selected_Id) {
          this.property_detail = property_detail;
        }
      } else {
        this.property_detail = this.share.block0_obj.property_detail;
      }
    }
    if (this.share.block0_obj.entity_type == 'property_floor') {
      let floor_detail = JSON.parse(localStorage.getItem('floor_detail'))
      if (floor_detail != null && this.form_language == this.api.language) {
        if (floor_detail.floor_id == this.selected_Id) {
          this.property_detail = floor_detail;
        }
      } else {
        this.property_detail = this.share.floor_obj.floor_detail;
      }
    }
    if (this.share.block0_obj.entity_type == 'property_unit') {
      let unit_detail = JSON.parse(localStorage.getItem('unit_detail'))
      if (unit_detail != null && this.form_language == this.api.language) {
        if (unit_detail.unit_id == this.selected_Id) {
          this.property_detail = unit_detail;
        }
      } else {
        this.property_detail = this.share.unit_obj.unit_detail;
      }
    }
  }

  setMasterValue() {
    if (this.share.block0_obj.table_master_val.length > 0) {
      this.master_val = []; let label = '';
      this.share.block0_obj.table_master_val.forEach((v) => {
        label = v.master_name;
        if (v.labels[this.form_language]) {
          label = v.labels[this.form_language];
        }
        let val = {
          label: label,
          labels: v.labels,
          master_id: v.master_id,
          master_name: v.master_name,
          id: v.id,
          status: v.status,
          property_type_id: v.property_type_id,
          master_value_id: v.master_value_id,
          parent_master_value_ids: v.parent_master_value_ids,
          name: label, // adding this for drop down
          image_url:v.image_url
        };
        this.master_val.push(val);
      });
    }
  }
  createFormElement() {
    this.setMasterValue();

    let group = this.fb.group({});
    let tbl_attr = [], table_attr = [];
    this.customize_attribute.forEach(
      control => {
        if (control.data_type == 'table') { // for type table
          let tbl = { id: control.id, child: [] };
          tbl_attr.push(control.id);
          if (control.child.length > 0) {
            control.child.forEach((attr) => {
              tbl_attr.push(attr.id);
            });
            tbl.child = control.child;
          }
          table_attr.push(tbl);
        }
        if (tbl_attr.indexOf(control.id) == -1) {
          group.addControl('input_' + control.id, this.fb.control(''));
          if (control.data_type == 'single-select' || control.data_type == 'multi-select') {
            group.addControl('search_' + control.id, this.fb.control(''));
          }
          if (control.measure_unit) group.addControl('unit_' + control.id, this.fb.control({value:'', disabled: (control.measure_type == "currency")?true:false}));
          if (control.child.length > 0) { // control for child attributes
            control.child.forEach(child => {
              group.addControl('input_' + child.id, this.fb.control(''));
              if (child.data_type == 'single-select' || child.data_type == 'multi-select') {
                group.addControl('search_' + child.id, this.fb.control(''));
              }
              if (child.measure_unit) group.addControl('unit_' + child.id, this.fb.control({value:'', disabled: (child.measure_type == "currency")?true:false}));
              if (child.mutual.length > 0) { // control for child mutual attributes
                setTimeout(() => {
                  //this.hideElement(control.id); // hide child element for mutual exclussive
                }, 200);
                child.mutual.forEach(cm => {
                  group.addControl('input_' + cm.id, this.fb.control(''));
                  if (cm.measure_unit) group.addControl('unit_' + cm.id, this.fb.control({value:'', disabled: (cm.measure_type == "currency")?true:false}));
                });
              }
            }
            );
          }
          if (control.mutual.length > 0) { // control for mutual exclussive attributes
            control.mutual.forEach(mutual => {
              if (mutual) {
                group.addControl('input_' + mutual.id, this.fb.control(''));
                if (mutual.measure_unit) group.addControl('unit_' + mutual.id, this.fb.control(''));
              }
            });
          }
        }
      }
    );
    table_attr.forEach((v) => { // form element for table type
      group.addControl('input_' + v.id, this.fb.control(''));
      let attrib_detail = this.fnc.getArrayValue('id', v.id, this.attribute_list);
      let master_val = this.getMasterById(attrib_detail.master_id);
      master_val.forEach((m) => {
        v.child.forEach((c) => {
          group.addControl('input_' + m.id + "_" + c.id, this.fb.control(''));
          if (c.measure_unit) group.addControl('unit_' + m.id + "_" + c.id, this.fb.control({value:'', disabled: (c.measure_type == "currency")?true:false}));
        });
      });
    });
    return group;
  }
  checkDisabled(){
    this.attribute_list.forEach((value)=>{
      if(value.name == "demolished_comment") this.propertyForm.get(`input_${value.id}`).disable();
      if(value.name == "deleted_comment") this.propertyForm.get(`input_${value.id}`).disable();
   })
   }
  checkChild(attr, event, type) {
    let child = attr.child, val, mutual;
    let e = $(this.elementRef.nativeElement);
    if (child) {
      val = e.find("#input_" + attr.id).val();
      if (type == 'checkbox') {
        if (!event.target.checked) val = false;
      }
      child.forEach((v) => {
        if (val) {
          e.find("#input_" + v.id).prop('disabled', false);
        } else {
          e.find("#input_" + v.id).prop('disabled', true);
        }
        mutual = v.mutual;
        if (mutual) {
          mutual.forEach((m) => {
            if (val) {
              e.find("#input_" + m.id).prop('disabled', true);
            } else {
              e.find("#input_" + m.id).prop('disabled', true);
            }
          })
        }

      });
    }
  }

  setAttributeValue(id, pid = null) { // not in use for now
    let result = null;
    if (this.property_detail) {
      let attribute = this.fnc.getArrayValue('id', id, this.attribute_list);
      let val = this.property_detail?.data;
      if (val[id]) {
        if (attribute.data_type != 'table') {
          // result = val[id];
          if (attribute.measure_unit) {
            //result = val[id].split(' ')[0];
          }
        }
      }
    }
    return result;

  }
  formatDate(value) {
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
  }
  focusPicker(picker){
    picker.open();
  }
  patchFormData() {
    let e = $(this.elementRef.nativeElement);
    let data = {}, patchData = {}, pval;
    if (this.property_detail) {
      if (this.property_detail?.data) {
        data = this.property_detail?.data;
      }
    }
    if (data) {
      let val = null, key, unit_key, control;
      let rec = {}, unit_rec = {};
      this.attribute_list.forEach((v) => {
        if (!v.is_editable) {
          // control = this.propertyForm.get("input_"+v.id);
          // control.disabled();
          setTimeout(() => {
            e.find("#input_" + v.id).addClass("disabled-div");
            e.find("#unit_" + v.id).addClass("disabled-div");
            e.find("#span_" + v.id).addClass("disabled-div");
          }, 200);
        }
        let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
        if (data[v.id] && !v.is_hidden && v.status) {
          key = 'input_' + v.id;
          if (v.data_type != 'table') {

            if (v.measure_unit && String(data[v.id]).indexOf(' ') != -1) {
              let unit_data = data[v.id].split(' ');
              rec[key] = unit_data[0];
              if (unit_data[1]) {
                unit_key = "unit_" + v.id;
                unit_rec[unit_key] = unit_data[1];
                this.propertyForm.patchValue(unit_rec);
              }

            } else {
              val = data[v.id];
              if (v.data_type == 'year' || v.data_type == 'month') { // convert each value to string
                val = String(val);
              }
              if (v.data_type == 'date') { // convert each value to string
                val = this.formatDateaApi(val);

              }
              if (v.data_type == 'single-select') val = String(val);
              if (v.data_type == 'single-select' && attrib_detail) {
                val = String(val);
                if (attrib_detail.child.length) {
                  pval = val;
                  setTimeout(() => {
                    this.changeChild(pval, attrib_detail, true); // patch value of child element
                  }, 600);
                }
              }
              if (v.data_type == 'multi-select') { // convert each value of multi select to string
                if (val.length > 0) {
                  if (attrib_detail) { // patch value of child element
                    if (attrib_detail.child.length) {
                      pval = val;
                      setTimeout(() => {
                        this.changeMultiChild(pval, attrib_detail,true);
                      }, 600);
                    }
                  }
                  let multi_val = [];
                  val.forEach((multi) => {
                    // multi_val.push(String(multi));
                    multi_val.push(multi);
                  });
                  val = multi_val;
                } else {
                  val = null;
                }
              }
              rec[key] = val;
            }
            if (attrib_detail && attrib_detail.mutual.length) { // for mutual attribute condition

              if (data[attrib_detail.id]) {
                setTimeout(() => {
                  e.find("#radio_" + attrib_detail.id + "_1").prop('checked', true);
                }, 200);
                attrib_detail.mutual.forEach((mt) => {
                  patchData["input_" + mt.id] = null;
                  this.propertyForm.patchValue(patchData);
                });
              }
              if (attrib_detail.child.length) {
                attrib_detail.child.forEach((c) => {
                  if (data[c.id]) {
                    setTimeout(() => {
                      e.find("#radio_" + c.id + "_1").prop('checked', true);
                    }, 200);
                  }
                  if (c.mutual.length) {
                    c.mutual.forEach((m) => {
                      if (data[m.id]) {
                        setTimeout(() => {
                          e.find("#radio_" + c.id + "_2").prop('checked', true);
                          e.find("#div_" + c.id + "_1").addClass("disabled-div");
                          e.find("#input_" + c.id).addClass("disabled-div");
                          e.find("#div_" + c.id + "_2").removeClass("disabled-div");
                        }, 200);
                      }
                    })
                  }
                })
              }
            }
            this.propertyForm.patchValue(rec);
          }
          if (v.data_type == 'table') {
            if (data[v.id].length > 0) {
              let record = data[v.id];
              let master_val = this.getMasterById(v.master_id);
              let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
              master_val.forEach((m) => {
                if (attrib_detail.child.length > 0) {
                  let attr_m = this.fnc.getArrayValue('0', m.id, record);
                  if (!attr_m) return;
                  attrib_detail.child.forEach((c) => {
                    key = 'input_' + m.id + '_' + c.id;
                    if (!c.is_hidden && c.status) {
                      if (!c.is_editable) {
                        // control = this.propertyForm.get("input_"+v.id);
                        // control.disabled();
                        setTimeout(() => {
                          e.find('#input_' + m.id + '_' + c.id).addClass("disabled-div");
                        }, 200);
                      }
                      if (c.measure_unit && String(attr_m[c.id]).indexOf(' ') != -1) {
                        let unit_data = attr_m[c.id].split(' ');
                        rec[key] = unit_data[0];
                        if (unit_data[1]) {
                          unit_key = 'unit_' + m.id + '_' + c.id;
                          unit_rec[unit_key] = unit_data[1];
                          this.propertyForm.patchValue(unit_rec);
                        }
                      } else {
                        val = attr_m[c.id];
                        if (c.data_type == 'single-select') val = String(val);
                        if (c.data_type == 'year' || c.data_type == 'month') { // convert each value to string
                          val = String(val);
                        }
                        if (c.data_type == 'multi-select') { // convert each value of multi select to string
                          if (val) {
                            if (val.length > 0) {
                              let multi_val = [];
                              val.forEach((multi) => {
                                multi_val.push(String(multi));
                              });
                              val = multi_val;
                            } else {
                              val = null;
                            }
                          }
                        }
                        //console.log(this.propertyForm.contains(key));
                        rec[key] = val;


                      }

                    }
                  });
                }
              });
              this.propertyForm.patchValue(rec);
            }
          }
        } else {

          if (attrib_detail && attrib_detail.mutual.length) {
            attrib_detail.mutual.forEach((m) => {
              if (data[m.id]) {
                setTimeout(() => {
                  e.find("#radio_" + attrib_detail.id + "_2").prop('checked', true);
                  this.hideElement(attrib_detail.id);
                  e.find("#div_" + attrib_detail.id + "_1").addClass("disabled-div");
                  e.find("#input_" + attrib_detail.id).addClass("disabled-div");
                  e.find("#div_" + attrib_detail.id + "_2").removeClass("disabled-div");
                  patchData["input_" + attrib_detail.id] = null;
                  this.propertyForm.patchValue(patchData);
                }, 300);

              } else {
                setTimeout(() => {
                  e.find("#radio_" + attrib_detail.id + "_1").prop('checked', true);
                }, 300);
              }
            });
            if (attrib_detail.child.length) {
              attrib_detail.child.forEach((c) => {
                if (data[c.id]) {
                  setTimeout(() => {
                    e.find("#radio_" + c.id + "_1").prop('checked', true);
                  }, 200);
                }
                if (c.mutual.length) {
                  c.mutual.forEach((m) => {
                    if (data[m.id]) {
                      setTimeout(() => {
                        e.find("#radio_" + c.id + "_2").prop('checked', true);
                        e.find("#div_" + c.id + "_1").addClass("disabled-div");
                        e.find("#input_" + c.id).addClass("disabled-div");
                        e.find("#div_" + c.id + "_2").removeClass("disabled-div");
                      }, 200);
                    } else {
                      setTimeout(() => {
                        e.find("#radio_" + c.id + "_1").prop('checked', true);
                      }, 200);
                    }
                  })
                }
              })
            }
          }

          // patch default value
          if (v.data_type != "table") {
            key = "input_" + v.id;
            unit_rec[key] = v.default_value;
            this.propertyForm.patchValue(unit_rec);
            if (v.measure_unit) {
              unit_key = "unit_" + v.id;
              rec[unit_key] = v.measure_unit;
              this.propertyForm.patchValue(rec);
            }
          }


        }

        // patch default value of table
        if (v.data_type == 'table') {
          let record = data[v.id];
          let master_val = this.getMasterById(v.master_id);
          let attrib_detail = this.fnc.getArrayValue('id', v.id, this.customize_attribute);
          if (attrib_detail) {
            master_val.forEach((m) => {
              let attr_m = this.fnc.getArrayValue('0', m.id, record);
              if (attrib_detail.child.length > 0) {
                attrib_detail.child.forEach((c) => {
                  if (!c.is_hidden && c.status) {
                    if (c.measure_unit) {
                      unit_key = 'unit_' + m.id + '_' + c.id;
                      unit_rec[unit_key] = c.measure_unit;
                      this.propertyForm.patchValue(unit_rec);
                      if((attr_m && !attr_m[c.id]) || !attr_m) {
                        key = 'input_' + m.id + '_' + c.id;
                        rec[key] = c.default_value;
                        this.propertyForm.patchValue(rec);
                      }
                    } else if((attr_m && !attr_m[c.id]) || !attr_m) {
                      key = 'input_' + m.id + '_' + c.id;
                      rec[key] = c.default_value;
                      this.propertyForm.patchValue(rec);
                    }
                  }
                });
              }
            });
          }

        }
      });
    }
    this.formLoaded = true;
  }

  createProperty(event) {
    this.is_valid = true;
    if (!this.resource_access.PATCH) {
      if (this.share.block0_obj.entity_type == "property") this.notify.notify("You are not authorized to update property", 'warn');
      if (this.share.block0_obj.entity_type == "property_floor") this.notify.notify("You are not authorized to update property floor", 'warn');
      if (this.share.block0_obj.entity_type == "property_unit") this.notify.notify("You are not authorized to update property unit", 'warn');
      return;
    }
    let updatedAttribute = 0;
    let preVal = [];
    if (this.property_detail) {
      if (this.property_detail?.data) {
        preVal = this.property_detail?.data;
      }
    }
    let value = event;
    let x, attr_id, unit = '', attribute_c, attribute, lang_attribute = {}, simple_attribute = {}, val;
    let data = {}, body = {}, used_attr = [], table_attr = [];
    let e = $(this.elementRef.nativeElement);
    for (x in value) {
      let s = x.split('_');
      if (s[0] === 'search') {
        continue;
      }
      if (x.indexOf('unit') == -1) {
        unit = '';
        let attr_array = x.split('_');
        let attr_id = attr_array[attr_array.length - 1]; // last index wil be attribute id
        e.find("#input_" + attr_id).removeClass("red-border");
        attribute_c = this.fnc.getArrayValue('id', attr_id, this.customize_attribute);
        attribute = this.fnc.getArrayValue('id', attr_id, this.attribute_list);
        if (attribute.is_editable && !attribute.is_hidden && attribute.status) {
          if (attribute.data_type == 'table') { // for type table
            let tbl = { id: attribute_c.id, child: [] };
            used_attr.push(attribute_c.id);
            if (attribute_c.child.length > 0) {
              attribute_c.child.forEach((attr) => {
                used_attr.push(attr.id);
              })
              tbl.child = attribute_c.child;
            }
            table_attr.push(tbl);
          }
          if (used_attr.indexOf(+attr_id) == -1) {

            if (attribute.measure_unit) {
              e.find("#unit_" + attr_id).removeClass("red-border");
              unit = value["unit_" + attr_id];
              val = null;
              if (unit && value[x]) val = value[x] + " " + unit;
              if ((!unit || !value[x]) && attribute.is_mandatory) {
                e.find("#unit_" + attr_id).addClass("red-border");
                e.find("#input_" + attr_id).addClass("red-border");
                this.is_valid = false;
                val = null;
              }
            } else {
              val = value[x];

              if (!val && attribute.is_mandatory) {
                if(attribute.data_type == 'toggle'){
                  document.getElementById("toggle_"+attr_id).classList.add("red-border");
                }else{
                  e.find("#input_" + attr_id).addClass("red-border");
                }
                this.is_valid = false;
              }else{
                if(attribute.data_type == 'toggle'){
                  document.getElementById("toggle_"+attr_id).classList.remove("red-border");
                }
              }
            }
            if (attr_id == this.share.block0_obj.property_name_attr_id && val) {
              let _building_name = { [this.api.language.toString()]: val };
              body['own_address'] = {
                'building_name': _building_name
              }; // synching own address
            }
            // if ((attribute.data_type == 'string-input') && val) {
            //   val = val.trim();
            // }
            let oldVal = (preVal[attr_id]) ? preVal[attr_id] : null;
            if (attribute.data_type == "toggle" && !val) {
              val = false;   // set false value to toggel in case of null value
              if (!oldVal) oldVal = false;
            }
            if (attribute.data_type == "date" && !val) {
              val = null;   // set false value to toggel in case of null value
            }
            if (attribute.data_type == "date" && val) {
              val = this.formatDate(new Date(val));
            }
            if ((attribute.data_type == "toggle" && val != oldVal) || this.compairValue(val, oldVal)) { // send only changed value
              //updatedAttribute++;

              if (attribute.data_type == 'single-select') {// convert empty string value to null
                if(val === ''){
                  val = null;
                }
              }

              if (attribute.data_type == 'multi-select') { // convert each value to integer
                if (val.length > 0) {
                  let multi_val = [];
                  val.forEach((multi) => {
                    if (multi) multi_val.push(parseInt(multi));
                  });
                  val = multi_val;
                } else {
                  val = null;
                }
              }

              if (attribute.data_type == 'year' || attribute.data_type == 'month') { // convert each value to integer
                val = parseInt(val);
              }
              if (attribute.is_mandatory && !val) {  // null value is not allow in mandatory case

              } else {
                if (attribute.is_lang_dependent) {
                  lang_attribute[attr_id] = val;
                }
                if (!attribute.is_lang_dependent) {
                  simple_attribute[attr_id] = val;
                }
              }
            }

          }
        }
      }
    }
    if (table_attr.length > 0) { // get data of table type attribute
      table_attr.forEach((v) => {
        let tbl_val = [];
        let attrib_detail = this.fnc.getArrayValue('id', v.id, this.attribute_list);
        let master_val = this.getMasterById(attrib_detail.master_id);
        let record = preVal[v.id];
        master_val.forEach((m) => {
          let tbl = {};
          let attr_m = this.fnc.getArrayValue('0', m.id, record);
          tbl["0"] = m.id;
          v.child.forEach((c) => {
            if (c.is_editable && !c.is_hidden && c.status) {
              if (c.measure_unit) {
                e.find("#unit_" + m.id + "_" + c.id).removeClass("red-border");
                val = value["input_" + m.id + "_" + c.id];
                if(val){
                  unit = value["unit_" + m.id + "_" + c.id];
                  val = val + " " + unit;
                }else{ // make it null if no value is set
                  val = null;
                }
                if ((!unit || !value["input_" + m.id + "_" + c.id]) && attribute.is_mandatory) {
                  e.find("unit_" + m.id + "_" + c.id).addClass("red-border");
                  this.is_valid = false;
                  val = null;
                }

                if ((!unit || !value["input_" + m.id + "_" + c.id])) {
                  e.find("unit_" + m.id + "_" + c.id).addClass("red-border");
                  this.is_valid = false;
                  val = null;
                }
              } else {
                val = value["input_" + m.id + "_" + c.id];
                if (!val && c.is_mandatory) {
                  document.getElementById("input_" + m.id + "_" + c.id).classList.add("red-border");
                  // e.find("input_" + m.id + "_" + c.id).addClass("red-border");
                  this.is_valid = false;
                }else{
                  document.getElementById("input_" + m.id + "_" + c.id).classList.remove("red-border");
                }
              }

              //let oldVal = (attr_m[c.id]) ? attr_m[c.id] : null;
              if (val != '' || val != null){ //  && this.compairValue(val,oldVal)
                //updatedAttribute++;
                if (c.data_type == 'multi-select') { // convert each value to integer
                  if (val && val.length > 0) {
                    let multi_val = [];
                    val.forEach((multi) => {
                      if (multi) multi_val.push(parseInt(multi));
                    });
                    val = multi_val;
                  } else {
                    val = null;
                  }
                }
                if (c.data_type == 'year' || c.data_type == 'month') { // convert each value to integer
                  val = parseInt(val);
                }
                if (c.data_type == 'number-input' && !c.measure_unit) {
                  tbl[c.id] = + val;
                } else {
                  tbl[c.id] = val;
                }
              }
            }
          });

          if (this.fnc.jsonLength(tbl) > 1) tbl_val.push(tbl);
        });
        if (attrib_detail.is_lang_dependent && tbl_val.length) {
          lang_attribute[v.id] = tbl_val;
        }
        if (!attrib_detail.is_lang_dependent && tbl_val.length) {
          simple_attribute[v.id] = tbl_val;
        }
      });
    }
    if (this.fnc.jsonLength(lang_attribute) || this.fnc.jsonLength(simple_attribute)) updatedAttribute = 1;
    if(this.is_valid == false){
      this.notify.notify('Required Field cannot be empty', 'warn');
      return
    }
    if (updatedAttribute) {
      data["0"] = simple_attribute;
      data[this.form_language] = lang_attribute;
      body["data"] = data;
      if (this.property_detail.status == 4) {
        body["status"] = 6;
        if (this.property_detail.attribute_info) {
          for (let key in this.property_detail.attribute_info) {
            if (this.property_detail.attribute_info[key].status == -1) { // -1 is rejected, 3 edited by user.
              this.property_detail.attribute_info[key].status = 3;
            } else {
              this.property_detail.attribute_info[key].status = 1;
            }
          }
          body["attribute_info"] = this.property_detail.attribute_info;
        }
      }

      body["updated_by"] = this.api.user_id;
      body["assignment_id"] = this.share.block0_obj.assignment_id;
      let url = "";
      if (this.share.block0_obj.entity_type == 'property') {
        url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.selected_Id;
      }
      if (this.share.block0_obj.entity_type == 'property_floor') {
        url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.selected_Id;
      }
      if (this.share.block0_obj.entity_type == 'property_unit') {
        url = "deadmin/" + this.assignment_detail.assignment_name + "/properties/" + this.share.block0_obj.selected_property.id + "/floors/" + this.share.floor_obj.selected_floor_id + "/units/" + this.selected_Id;
      }
      this.share.main_property_obj.gLoader = true;
      this.globalLoader.ldr1 = true;//global Loader check
      this.loadCheck();
      this.api.patchEpsData(url, body)
        .subscribe({next:(data: any) => {
          this.globalLoader.ldr1 = false;//global Loader check
          this.loadCheck();
          this.share.main_property_obj.gLoader = false;
          this.notify.notify('Information Updated', 'success');
          if (this.share.block0_obj.entity_type == 'property') {
            this.share.block0_obj.getPropertyDetail();
          }
          if (this.share.block0_obj.entity_type == 'property_floor') {
            this.share.floor_obj.getFloorDetail();
          }
          if (this.share.block0_obj.entity_type == 'property_unit') {
            this.share.unit_obj.getUnitDetail();
          }
          setTimeout(() => {
            this.setPropertyDetail();
            this.syncWebView(simple_attribute, lang_attribute);
          }, 3000);
        },
          error:(err) => {
            this.globalLoader.ldr1 = false;//global Loader check
            this.loadCheck();
            this.notify.notify(err?.error?.message, 'error');
            this.share.main_property_obj.gLoader = false;
          }});
    }
  }

  syncWebView(simple_attribute, lang_attribute) {//Update list UI with current data
    if (this.mobile) {
      return;
    }
    let valObj, index;
    if (this.share.block0_obj.entity_type == 'property') {
      index = this.fnc.getArrayindex('id', this.selected_Id, this.share.block0_obj.property_list_custom);
      valObj = this.share.block0_obj.property_list_custom[index];
    }
    if (this.share.block0_obj.entity_type == 'property_floor') {
      index = this.fnc.getArrayindex('id', this.selected_Id, this.share.floor_obj.floorData);
      valObj = this.share.floor_obj.floorData[index];
    }
    if (this.share.block0_obj.entity_type == 'property_unit') {
      index = this.fnc.getArrayindex('id', this.selected_Id, this.share.unit_obj.unitData);
      valObj = this.share.unit_obj.unitData[index];

    }
    if (!valObj) return;
    this.attribute_list.forEach((v) => {
      if (v.is_listable && (v.data_type != 'table' && v.data_type != 'single-select' && v.data_type != 'multi-select')) {
        let data = this.property_detail?.data;
        if (data[v.id] != undefined && valObj[v.name] != undefined) {
          valObj[v.name] = data[v.id];
        }
      }
    })
  }

  compairValue(val1, val2) {
    let res = true;
    if (!val1 && !val2) res = false;
    if (val1 && val2) {
      if (val1.toString() == val2.toString()) res = false;
    }
    return res;
  }

  // change input form language
  changeFormLanguage(v) {
    this.form_language = v;
    this.api.form_language = this.form_language;
    let val = this.fnc.getArrayValue('language_id', v, this.language_list);
    this.selected_langauge = val.language_code;
    this.setMasterValue();
    this.createFormGroup();
    this.loader = true;
    if (this.share.block0_obj.entity_type == 'property') {
      this.share.block0_obj.getPropertyDetail();
    }
    if (this.share.block0_obj.entity_type == 'property_floor') {
      this.share.floor_obj.getFloorDetail();
    }
    if (this.share.block0_obj.entity_type == 'property_unit') {
      this.share.unit_obj.getUnitDetail();
    }
    if (this.api.form_language == 2) {
      $("#property_form").css("direction", "rtl");
    } else {
      $("#property_form").css("direction", "ltr");
    }
    setTimeout(() => {
      this.setPropertyDetail();
      this.patchFormData();
      this.loader = false;
    }, 2500);
  }
  // grouping attributes
  public createFormGroup() {
    let val = {}, new_list = [];
    this.masterIDs = [];
    let mtl = [];
    this.attribute_list.forEach((v) => {
      if (v['master_id']) {
        this.masterIDs.push({
          master_id: v.master_id,
          attribute_id: v.id
        })
      }
      if (this.mobile) {
        if (v.data_type == 'images' || v.data_type == 'videos') {
          this.route.navigateByUrl('uploadMedia');
          return;
        }
      } else {
        if (v.data_type == 'images' || v.data_type == 'videos') {
          if (this.share.unit_obj && this.share.unit_obj.selected_unit) {
              setTimeout(() => {
                this.notif.emit('upload_Media_unit');
              }, 200);
          } else if (this.share.floor_obj && this.share.floor_obj.selected_floor) {
              setTimeout(() => {
                this.notif.emit('upload_Media_floor');
              }, 200);
          } else {
            setTimeout(() => {
              this.notif.emit('uploadMedia');
            }, 400);
          }
          return;
        }
      }
      let label = v.name;
      if (v.labels[this.form_language]) {
        label = v.labels[this.form_language];
      }
      val = {
        id: v.id,
        name: v.name,
        parent_id: v.parent_id,
        label: label,
        data_type: v.data_type,
        measure_type: v.measure_type,
        measure_unit: v.measure_unit,
        default_value: v.default_value,
        is_mandatory: v.is_mandatory,
        is_hidden: v.is_hidden,
        is_editable: v.is_editable,
        status: v.status,
        mutually_exclusive: (typeof v.mutually_exclusive == 'string') ? [v.mutually_exclusive]: v.mutually_exclusive,
        master_id: v.master_id,
        has_linked_master: v.has_linked_master,
        dependent: v.dependent,
        mutual: [],
        child: []
      }
      new_list.push(val);
    });


    new_list.forEach((v) => {
      mtl = [];
      if (v.mutually_exclusive) {
        v.mutually_exclusive.forEach((m) => {
          this.used_attribute.push(m);
          mtl.push(this.fnc.getArrayValue('id', m, new_list));
        });
        v.mutual = mtl;
      }
      if (v.parent_id) {
        this.used_attribute.push(v.id);
        let index = this.fnc.getArrayindex('id', v.parent_id, new_list);
        if (index !== null) {
          new_list[index].child.push(v);
        }
      }
    });
      this.group_attribute = new_list;
    this.customize_attribute = this.fnc.getDeletedArray('id', this.used_attribute, new_list); // remove duplicate list
  }
  // grouping aatributes end

  getNewMasterVal(list, attrib, parent) {
    let info = {
      list: list,
      attrib: attrib,
      parent: parent,
      pObj: this
    }
    let dialogRef = this.dialog.open(AddNewMaster, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: info
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  addNewMasterValue(value, attrib, parent, selectedMaster = null) {
    let e = $(this.elementRef.nativeElement);
    this.addOtherOption = true;
    let parent_master_value_id = null;
    if (selectedMaster) {
      parent_master_value_id = selectedMaster;
    }
    if (parent && !selectedMaster) {
      parent_master_value_id = this.propertyForm.get('input_' + parent.id).value;
    }
    let dialogRef = this.dialog.open(ConfirmExit, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: this
    });
    dialogRef.afterClosed().subscribe(result => {
      this.searchTerm = null;
      this.addOtherOption = false;
      // this.active_field = {
      //   id: null,
      //   label: null
      // };
      if (this.confirmation) {
        this.loader1 = true;
        let property_type_ids, body, ms_value, url = this.share.block0_obj.entity_type + '/masters/' + attrib.master_id + '/values';
        property_type_ids = null;
        ms_value = value.toLowerCase();
        ms_value = ms_value.trim();
        if (this.share.block0_obj.entity_type == "property_unit") {
          property_type_ids = [this.share.unit_group_obj.selected_unit.property_type_id];
        }
        body = {
          "added_by": this.api.user_id,
          "master_value_label": {
            [this.form_language]: value
          },
          "master_value_name": ms_value.replace(/ /g, "_"),
          "parent_master_value_id": parent_master_value_id,
          "parent_master_value_ids": parent_master_value_id,
          "property_type_ids": property_type_ids,
          "data_source": 3,
          "sort_order": 0,
          "status": 1
        }
        let rec = {};
        this.globalLoader.ldr2 = true;//global Loader check
        this.loadCheck();
        this.api.postEmsData(url, body).subscribe({
          next: (data: any) => {
          if (attrib.data_type == 'single-select') {
            let childrens = this.fnc.getChildArray('parent_id', attrib.id, this.attribute_list);
            this.selectedValues = [];
            if (childrens != null) {
              childrens.forEach(ch => {
                this.propertyForm.patchValue({ ['input_' + ch.id]: null });
              });
            }
          }
          this.globalLoader.ldr2 = false;//global Loader check
          this.loadCheck();
          this.loader1 = false;
          this.confirmation = false;
          let res = data;
          let val = {
            master_id: attrib.master_id,
            master_name: body.master_value_name,
            label: value,
            id: res.master_value_ids[0],
            status: 1,
            property_type_id: body.property_type_ids,
            master_value_id: body.parent_master_value_id,
            parent_master_value_ids: body.parent_master_value_id + '',
            name: value // added for drop down
          }
          this.master_val.push(val);

          if (attrib.data_type == 'single-select') {

            rec['input_' + attrib.id] = val.id.toString();
            this.propertyForm.patchValue(rec);
            setTimeout(() => {
              if (!parent) this.changeChild(val.id, attrib);
            }, 100);

          }
          if (attrib.data_type == 'multi-select') {
            let values: any = [];
            if (this.propertyForm.get('input_' + attrib.id).value) {
              values = this.propertyForm.get('input_' + attrib.id).value;
            }
            values.push(val.id);
            rec['input_' + attrib.id] = values;
            this.propertyForm.patchValue(rec);
          }
          e.find("#input_" + attrib.id).click(); // to show value
          e.find("#child_" + attrib.id).css('border', '1px solid red');
          this.notify.notify("Value successfully added!", 'success');
        }, error:() => {
          this.globalLoader.ldr2 = false;//global Loader check
          this.loadCheck();
          rec['input_' + attrib.id] = null;
          this.propertyForm.patchValue(rec);
          this.loader1 = false;
          this.confirmation = false;
        }});
      } else {
        let tag = 'input_' + attrib.id
        this.propertyForm.patchValue({ [tag]: '' });
      }
    });
  }

  changeChild(event, attrib, onload = false) {
    let val = event;
    if (val == 'addNewOption') {
      return;
    }
    if (val) {
      if (attrib.child.length) {
        let fld = null;
        attrib.child.forEach((child) => {
          this.linked_master = [];
          this.child_master_val[child.id] = [];
          if (child.data_type == "single-select" || child.data_type == "multi-select") {
            this.child_master_val[child.id] = this.getMasterByValueId(child, +val);
            if (child.has_linked_master) {
              fld = 'input_' + child.id;
              if ((!this.propertyForm.get('input_' + child.id).value && onload) || !onload) {
                if(child.data_type == "single-select"){
                  this.propertyForm.patchValue({ [fld]: this.linked_master.length>0 ? this.linked_master[0] : '' });

                }else if(child.data_type == "multi-select"){
                  this.propertyForm.patchValue({ [fld]: this.linked_master });

                }
              }
            }
          }
        });
      }
    }
  }

  changeMultiChild(event, attrib, onload = false) {
    let val = event;
    let fld = null;
    let last_val: any = [];
    let new_val: any = [];
    let final_val: any = [];
    if (val) {
      if (attrib.child.length) {
        attrib.child.forEach((child) => {
          this.child_master_val[child.id] = [];
          if (child.data_type == "single-select" || child.data_type == "multi-select") {
            this.linked_master = [];
            val.forEach((v) => {
              fld = null;
              if (this.child_master_val[child.id].length) {
                last_val = this.child_master_val[child.id];
              }
              new_val = this.getMasterByValueId(child, +v);
              if (new_val.length) {
                final_val = new_val;
              }
              if (last_val.length) {
                final_val = last_val;
              }
              if (last_val.length && new_val.length) {
                final_val = new_val.concat(last_val);
              }

              this.child_master_val[child.id] = final_val;
              if (child.has_linked_master) {
                this.linked_master = this.fnc.removeDuplicateKey(this.linked_master);// remove duplicate value
                fld = 'input_' + child.id;
                if ((!this.propertyForm.get('input_' + child.id).value && onload) || !onload) {
                  if(child.data_type == "single-select"){
                    this.propertyForm.patchValue({ [fld]: this.linked_master.length > 0 ? this.linked_master[0]: ''});
                  }else if(child.data_type == "multi-select") {
                    this.propertyForm.patchValue({ [fld]: this.linked_master });
                  }
                }
              }
            });
          }
        });
      }
    }
  }

  getChildMasterValue(attrib, parent) {
    let res = [];
    let id = attrib.id;
    if (parent.data_type == "single-select" || parent.data_type == "multi-select") {
      if (this.child_master_val[id]) {
        res = this.child_master_val[id];
      }
      return res;
    } else {
      return this.getMasterById(attrib.master_id);
    }

  }

  getMasterById(master_id) {
    let record = [];
    record = this.fnc.getChildArray('master_id', master_id, this.master_val);
    return record;
  }

  getMasterByValueId(attrib, master_value_id) {
    let master_id = attrib.master_id;
    let record = [];
    let sub_master = this.fnc.getChildArray('master_id', master_id, this.master_val);
    if (attrib.has_linked_master) {
      record = sub_master;
      sub_master.forEach((v) => {
        if (v.parent_master_value_ids) {
          let ms = v.parent_master_value_ids.split(",");
          if (ms.indexOf('' + master_value_id) > -1) {
            this.linked_master.push(v.id);
          }
        }
      });
    } else {
      record = this.fnc.getChildArray('master_value_id', master_value_id, sub_master);
    }
    return record;
  }

  getMeasureUnit(val) {
    if (val) {
      let units = this.fnc.getArrayValue('unit_type', val, this.measure_unit_type);
      if (units) {
        return units.measure_unit;
      }
    }
    return [];
  }

  getUnitByType(val) {
    if (val) {
      this.globalLoader.ldr5 = true;//global Loader check
      this.loadCheck();
      this.api.getEmsData("attribute/" + val + "/measureunits")
        .subscribe((data: any) => {
          this.globalLoader.ldr5 = false;//global Loader check
          this.loadCheck();
          this.measure_unit_type.push({
            unit_type: val,
            measure_unit: data
          });
          localStorage.setItem('measure_unit_type', JSON.stringify(this.measure_unit_type));
        }
        );
    }
  }
  getMeasureUnitType() {
    let units = JSON.parse(localStorage.getItem('measure_unit_type'))
    if (units) {
      this.measure_unit_type = units;
      return;
    }
    let url = "attribute/measureunittypes";
    this.globalLoader.ldr6 = true;//global Loader check
    this.loadCheck();
    this.api.getEmsData(url).subscribe((data: any) => {
      this.globalLoader.ldr6 = false;//global Loader check
      this.loadCheck();
      data.forEach((v) => {
        this.getUnitByType(v);
      });
    });
  }

  showChild(attr, event = null, num) {
    let id = attr.id;
    let patchData = {};
    let e = $(this.elementRef.nativeElement);
    //this.propertyForm.controls.nam
    if (event.target.value == 'on') {
      e.find('#child_' + id).removeClass('hidden');
    } else {
      e.find('#child_' + id).addClass('hidden');
    }
    if (num == 1) {
      e.find("#div_" + id + "_2").addClass("disabled-div");
      e.find("#input_" + id).removeClass("disabled-div");
      e.find("#div_" + id + "_1").removeClass("disabled-div"); // special case for toggle
      if (attr.data_type == "toggle") {
        e.find("#input_" + id).prop('checked', true);
        patchData["input_" + id] = true;
      } else {
        patchData["input_" + id] = null;
      }
      if (attr.mutual.length) {
        attr.mutual.forEach((c) => {
          patchData["input_" + c.id] = null;
          this.propertyForm.patchValue(patchData);
        });
      }

    }
    if (num == 2) {
      e.find("#div_" + id + "_2").removeClass("disabled-div");
      e.find("#div_" + id + "_1").addClass("disabled-div");
      e.find("#input_" + id).addClass("disabled-div");
      if (attr.data_type == "toggle") {
        e.find("#input_" + id).prop('checked', false);
        patchData["input_" + id] = false;
      } else {
        patchData["input_" + id] = null;
      }
      this.propertyForm.patchValue(patchData);
      if (attr.child.length) {
        attr.child.forEach((c) => {
          patchData["input_" + c.id] = null;
          this.propertyForm.patchValue(patchData);
          if (c.mutual.length) {
            c.mutual.forEach((m) => {
              patchData["input_" + m.id] = null;
              this.propertyForm.patchValue(patchData);
            });
          }
        });
      }

    }
  }

  hideElement(id) {
    let e = $(this.elementRef.nativeElement);
    e.find('#child_' + id).addClass('hidden');
  }

  backClicked() {
    this.submitForm();
    this.is_submited = true;
    if (this.is_valid) {
      this.location.back();
    } else {
      let dialogRef = this.dialog.open(ConfirmExit, {
        width: '350px',
        panelClass: 'create-master-panel',
        data: this
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  onChanges(): void {
    this.propertyForm.valueChanges.subscribe(val => {
      this.is_submited = false;
    });
    // for focus in case of mobile only
    if (this.mobile) {
      let e = $(this.elementRef.nativeElement);
      e.find(':input').on('focus', function () {
        setTimeout(() => {
          if (this.id) {
            e.find("#" + this.id)[0].scrollIntoView();
          };
        }, 350);
      });
    }
  }
  isRejected(id) {
    let attrib = this.fnc.getArrayValue('keyId', id, this.rejected_attribute);
    if (attrib != null) {
      if (attrib.keyId == id) {
        return true;
      }
    }
    return false;
  }
  setRejectedMessage(id) {
    let attrib = this.fnc.getArrayValue('keyId', id, this.rejected_attribute);
    if (attrib != null) {
      this.rejectmessage = attrib.comment;
    }
  }
  submitForm() {
    if (!this.is_submited && this.formLoaded) {
      this.is_submited = true;
      let e = $(this.elementRef.nativeElement);
      e.find("#property_form_btn").trigger('click');
    }
  }
  // onResize(event) {
  //   let height = event.target.innerHeight - 100;
  //   this.dHeight = height;
  //   let e = $(this.elementRef.nativeElement);
  //   e.find('#pList').css('height', this.dHeight);
  //   e.find('#DynamicBlock').css('height', (this.dHeight - 50));
  // }
  ngOnInit() {
    let e = $(this.elementRef.nativeElement);
    setTimeout(() => {
      this.dHeight = this.share.heightService;
      e.find('#pList').css('height', this.dHeight);
      e.find('#DynamicBlock').css('height', (this.dHeight - 50));
      this.onChanges();
    }, 10);
    this.plocation.onPopState(() => {
      if (!this.is_submited) {
        this.submitForm(); // save form when user press back
      }
    });
    this.setPropertyDetail();
    setTimeout(() => {
      this.patchFormData();
      this.setMasterValue();
      this.getAttributeValue();
    }, 500);
    if (!this.property_detail) {
      this.loader = true;
      setTimeout(() => {
        this.setPropertyDetail();
        if (this.property_detail) {
          this.patchFormData();
          this.loader = false;
        }
      }, 1500);
    }
    this.checkDisabled()


  }

  ngOnDestroy(): void {
    //this.api.language = this.app_language;

  }

  isParentSelected(data) {
    let val = this.propertyForm.get('input_' + data.parent_id).value;
    if (val) {
      return true;
    } else {
      return false;
    }
  }

  getMasterValues(v, masterValueIds = null) {
    this.searchTerm = '';
    this.confirmation = false
    let m_val = this.getMasterById(v);
    let val_ids = [];
    let curMaster: any;
    if(Array.isArray(masterValueIds) && typeof masterValueIds[0] === 'object'){
      return;
    }
    if(masterValueIds){ // check if master value is in list or not
      masterValueIds = (masterValueIds.toString()).split(','); // convert into array
      for(let i = 0; i < masterValueIds.length; i++){
        curMaster = this.fnc.getArrayValue('id', masterValueIds[i], m_val);
        if (curMaster == null) {
          val_ids.push(masterValueIds[i]);
        }
      }
    }
    //if(m_val.length > 0 && masterValueIds == null) return;
    if (masterValueIds == null || val_ids.length == 0) return;

    let url = this.share.block0_obj.entity_type + "/masters/" + v + "/values?sort_by=master_value_name&limit=100&offset=0&status=1";
    if (this.share.block0_obj.property_type_id && this.share.block0_obj.entity_type == "property_unit") {
      url += "&property_type_ids=" + this.share.block0_obj.property_type_id;
    }

    url += '&master_value_ids=' + val_ids.toString();


    this.api.getEmsData(url)
      .subscribe((data: any) => {
        data.forEach(element => {
          let val = {
            label: element.master_value_label[this.form_language],
            labels: element.master_value_label,
            master_id: v,
            master_name: element.master_value_name,
            id: element.master_value_id,
            status: element.status,
            property_type_id: element.property_type_id,
            master_value_id: element.parent_master_value_id,
            parent_master_value_ids: element.parent_master_value_ids,
            name: element.master_value_label[this.form_language], // adding this for drop down
            image_url:element.image_url
          };
          this.master_val.push(val);
          // curMaster = this.fnc.getArrayValue('id', val.id, m_val);
          // if (curMaster == null) {
          //   this.master_val.push(val);
          // } else {
          // }
        });
      });
  }

  //getting selected values for multiselect dropdown
  getSelectedValues(attrib, single = false) {
    let selected = this.propertyForm.get('input_' + attrib.id).value;
    if (!single) {
      if (selected && selected.length) {
        this.selectedValues.push({
          attr_id: attrib.id,
          selected: selected
        })
        $('#input_custom_' + attrib.id).val(selected.length + " values selected");
      } else {
        $('#input_custom_' + attrib.id).val("No values selected");
      }
    } else if (selected && single) {
      let valObj = this.fnc.getArrayValue('id', selected, this.master_val);

      if (valObj != null) {
        let val = (valObj.label) ? valObj.label : valObj.master_name;
        if (val) $('#input_custom_' + attrib.id).val(val);
      }
    } else {
      $('#input_custom_' + attrib.id).val("No values selected");
    }
  }

  openMultiSelect(attrib, parent) {
    let selected;
    if(parent){
      selected = this.propertyForm.get('input_' + parent.id).value;
      if(Array.isArray(selected) && selected.length == 0){
        this.notify.notify('Please select a parent value', 'warn');
        return;
      }else{
        if(!selected){
          this.notify.notify('Please select a parent value', 'warn');
          return;
        }
      }
    }

    let dialogRef = this.dialog.open(MasterMultiselect, {
      width: '450px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: 'create-master-panel',
      data: {
        source: this,
        attrib: attrib,
        parent: parent,
        selected: this.selectedValues
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  getList(attrib, s, parent = null) {
    let l;
    if (parent) {
      l = this.getChildMasterValue(attrib, parent);
    } else {
      l = this.getMasterById(attrib.master_id);
    }

    if (!s || this.active_field.id != attrib.id) {
      return l;
    }
    if (this.newlist) {
      return this.newlist;
    } else {
      return l;
    }
  }

  newValue(attrib, s, parent = null) {
    let l, val, i, flag: boolean = false;
    if (parent) {
      l = this.getChildMasterValue(attrib, parent);
    } else {
      l = this.getMasterById(attrib.master_id);
    }
    if (s) {
      s = s.toLowerCase();
      s = s.trim();
      for (i = 0; i < l.length; i++) {
        val = l[i];
        if (val.label.toLowerCase() == s) {
          flag = false;
          break;
        } else {
          flag = true;
        }
      }
      return flag;
    } else {
      return flag;
    }
  }

  getAttributeValue() {
    let data = this.property_detail?.data;
    this.masterIDs.forEach((e) => {
      if (data[e.attribute_id]) { //&& _isNumberValue={}(data[e.attribute_id])
        this.getMasterValues(e.master_id, data[e.attribute_id]);
      }
    });

  }

  // MAT SELECT SEARCH IMPLIMENTATION------------------------------------------------------
}

@Component({
  selector: 'confirm-exit',
  templateUrl: './confirm-exit.component.html',
  styleUrls: ['./block3.component.scss']
})
export class ConfirmExit {
  addOtherOption: boolean = false;
  searchTerm: string;
  active_field: any = {
    id: null,
    label: null
  };
  constructor(public dialogRef: MatDialogRef<ConfirmExit>, @Inject(MAT_DIALOG_DATA) public data: any, private location: Location) {
    this.addOtherOption = data.addOtherOption;
    this.searchTerm = data.searchTerm;
    this.active_field = data.active_field;
  }
  ngOnInit() {

  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  clear(): void {
    this.data.searchTerm = '';
    this.dialogRef.close();
  }
  exit(): void {
    this.dialogRef.close();
    this.location.back();
  }
  confirm() {
    this.data.confirmation = true;

    this.dialogRef.close();
  }
}

@Component({
  selector: 'add-new-master',
  templateUrl: './add-new.component.html',
  styleUrls: ['./block3.component.scss']
})
export class AddNewMaster {
  searchTerm: string;
  master_list: any = [];
  err_msg = "";
  part2: boolean = false;
  masterVals: any = [];
  selectedMaster: string;
  parentMasterList: any;

  constructor(public dialogRef: MatDialogRef<AddNewMaster>, @Inject(MAT_DIALOG_DATA) public data: any, private fnc: CommonfunctionService) {
    this.master_list = this.data.list;
    if (this.data.parent.data_type == 'multi-select') {
      this.masterVals = this.data.pObj.propertyForm.get('input_' + this.data.parent.id).value;
      this.part2 = true;
    }
    this.parentMasterList = this.data.pObj.getMasterById(this.data.parent.master_id);
  }
  ngOnInit() {

  }
  getMasterLabel(id) {
    let val;
    val = this.fnc.getArrayValue('id', id, this.parentMasterList);
    if (val) {
      return val.label;
    }
  }
  cleanError() {
    if (this.err_msg) this.err_msg = null;
  }
  exit(): void {
    this.dialogRef.close();
  }
  confirm() {
    if (this.searchTerm && this.fnc.getArrayindex('label', this.searchTerm.toLowerCase(), this.master_list) == null) {
      this.data.pObj.active_field = this.data.attrib;
      this.data.pObj.searchTerm = this.searchTerm;
      this.data.pObj.addNewMasterValue(this.searchTerm, this.data.attrib, this.data.parent, this.selectedMaster);
      this.dialogRef.close();
    } else {
      this.err_msg = "Value already exists";
    }
  }
}

