import { ChangeDetectorRef, Component, Input, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";
import { NotificationService } from "../../../../../../src/app/services/notification.service";
import { ShareService } from "../../../../../../src/app/services/share.service";
import { FloorMapComponent } from "../../floor-map.component";
import { ToolboxDialogComponent } from "../tool-box-dialog/tool-box-dialog.component";
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: "toolbox-map",
  templateUrl: "./toolbox.component.html",
  styleUrls: ["../../floor-map.component.css"],
})
export class ToolboxComponent {
  @Input() selected_unit_uid : '';
  mobile: boolean = false;
  floors: any = [];
  parent: any;
  selected_floor: any;
  current_floor_info: any;
  addUnitAction: boolean = false;
  setActive: string = "";
  toggle: boolean = false;
  copied: any = "";
  unit:any;
  flagVal: Number = 0;
  selected_unit_uids:any;

  showLable:boolean=true;

  constructor(
    public share: ShareService,
    private fnc: CommonfunctionService,
    public dialog: MatDialog,
    private floorMapComp: FloorMapComponent,
    private route: Router,
    private clipboard: Clipboard,
    private notify: NotificationService,
    private cdr: ChangeDetectorRef
  ) {
this.share.selectedUnitId.subscribe((id:any)=> {
  this.selected_unit_uids = id;
  this.cdr.detectChanges();
})
    this.share.floor_map_obj.tenantName.subscribe((data) => {
      let s = this.share.floor_map_obj.unit_Uids[0];
      this.unit = this.share.floor_map_obj.getUnitDetail(s);
    });

    this.mobile = this.share.mobile;
    this.share.toolbox_obj = this;
    this.floors = this.share.floor_obj.floorData;
    this.selected_floor = this.share.floor_obj.selected_floor;
    this.current_floor_info = this.share.floor_obj.selected_floor;
    this.parent = this.share.floor_map_obj;
    this.current_floor_info = this.fnc.getArrayValue(
      "uid",
      this.selected_floor.uid,
      this.floors
    );

  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['selected_unit_uid']) {
    //   console.log('Child Component detected change in selected_unit_uid:', changes['selected_unit_uid'].currentValue);
    //   let a = changes['selected_unit_uid'] ? changes['selected_unit_uid'].currentValue : null;
    //   console.log(a, 'a is working');
    // }
  }

  ngAfterViewChecked() {
    // Manually trigger change detection if necessary
    // this.cdRef.detectChanges();
  }
  showTanentLable(event){
    if(event.checked){
      this.share.showTanent.next('true');
    }else{
      this.share.showTanent.next('false');
    }
  }
  setUnitId() {
    this.copied = this.share.floor_map_obj.copied.uid;
  }

  isActive(mode) {
    return mode == this.setActive;
  }
  public copyToClipboard(id): void {
    const successful = this.clipboard.copy(id);
    if (successful) {
      this.notify.notify(
        `Unit uid ${id} copied`,
        "success"
      );
    } else {
      this.notify.notify(`Not copied, try again`, "warn");
    }
  }
  currentFloor(e) {
    this.share.floor_map_obj.unit_Uids = [];
    this.parent.copied = null;
    this.parent.unitGisUids = [];
    this.parent.unitGisUidsForPaste = [];
    this.current_floor_info = {};
    let uid = e.source.value;
    this.current_floor_info = this.fnc.getArrayValue("uid", uid, this.floors);
    this.share.floor_map_obj.selected_floor = this.current_floor_info;
    this.share.floor_obj.selected_floor_id = this.current_floor_info.id;
    this.share.unit_obj.estaterUnitCount =
      this.share.floor_map_obj.selected_floor.units;
    this.parent.getUnitData();
    if (!this.mobile) {
      this.unitList(true);
    }
    this.share.floor_map_obj.clearDrawing();
    this.parent.map.switchFloor(this.current_floor_info.id);
  }
  unitList(flag = false) {
    this.flagVal = 1;
    this.share.floor_obj.unit_list = true;
    this.share.floor_obj.selected_floor = this.current_floor_info;
    this.parent.copied = null;
    if (this.mobile) {
      this.route.navigateByUrl("/unit");
    } else {

      if (!flag) {
        this.dialog.closeAll();
      }
      this.share.unit_obj.isFloorMap=false;
      this.share.unit_obj.unitData = [];
      this.share.unit_obj.unitDataGis = [];
      this.recallUnitApi();
      if (this.share.floor_map_obj?.unitGisUids?.length == 1) {
        this.share.dynamic_obj.onOptionsRequested("unitGroup");
      } else {
        this.share.dynamic_obj.onOptionsRequested("unit");
      }
    }
  }
  recallUnitApi() {
    setTimeout(() => {
      this.share.unit_obj.getUnitData();
      //this.share.unit_obj.getGisUnitData();
      this.share.unit_obj.getNoOfUnits();
    }, 200);
  }
  clear() {

    this.share.floor_map_obj.loader=true;
    this.parent.unitGisUidsForPaste = [];
    this.parent.unit_Uids = [];
    this.parent.copied = null;
    this.share.floor_map_obj.clearDrawing();
    this.parent.map.switchFloor(this.current_floor_info.id);
    this.copied = null;

    setTimeout(() => {
    this.share.floor_map_obj.loader=false;
      this.share.floor_map_obj.setColorunitLayout("6");
    }, 4000);
  }
  copyUnit() {
    this.parent.copy();
  }

  drawPolygon(mode) {
    // this.toggle = !this.toggle;
    if (!this.setActive) {
      this.toggle = true;
    } else {
      if (this.setActive == mode) {
        this.toggle = false;
      } else {
        this.toggle = true;
      }
    }

    if (!this.share.unit_obj.unit_access.POST) {
      this.share.floor_map_obj.notify(
        "You are not authorized to create unit",
        "warn"
      );
      return;
    }
    if (this.toggle) {
      if (mode == "circle") {
        this.share.floor_map_obj.enableDrawing = true;
        this.parent.drawUnitPolygon(mode);
      } else if (mode == "polygon") {
        this.addUnitAction = true;
        this.parent.drawUnitPolygon(mode);
      }
    } else {
      this.setActive = "";
      if (mode == "circle") {
        this.share.floor_map_obj.enableDrawing = false;
        this.parent.drawUnitPolygon();
      }
      if (mode == "polygon") {
        this.addUnitAction = false;
        this.share.floor_map_obj.enableDrawing = false;
        this.parent.drawingManager.setDrawingMode(null);
        this.share.floor_map_obj.clearDrawing();
      }
    }
  }

  openDialog(info: any = {}): void {
    info.pObj = this;
    let dialogRef = this.dialog.open(ToolboxDialogComponent, {
      width: "450px",
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: "create-unit-panel",
      data: info,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.parent.userShape) this.parent.userShape.setMap(null);
    });
    dialogRef.disableClose = true;
  }

  openGroupDialog() {
    if (this.parent.unitGisUidsForPaste.length) {
      this.parent.openDialog();
    }
  }
}
