<div class="inner-toolbox">
    <div style="display: flex; align-items: start; justify-content: space-between; gap:5px; padding: 4px 4px;">
        <!-- <span class="center lnk" (click)="drawPolygon('circle')" title="Drop circle">
            <mat-icon [ngClass]="{'active': isActive('circle')}" class="move-icn">add_circle_outline</mat-icon>
        </span>
        <span class="center lnk" (click)="drawPolygon('polygon')" title="Draw Polygon">
            <mat-icon [ngClass]="{'active': isActive('polygon')}" svgIcon="poly" class="move-icn"></mat-icon>
        </span> -->
        <span >
            <mat-checkbox class="example-margin" (change)="showTanentLable($event)" [(ngModel)]="showLable"></mat-checkbox>
        </span>
        <span class="grid-50c">
            <strong class="txtcolr1 fnt11" style="white-space: nowrap;">TENANT NAME</strong>
            <span class="txtcolr2 fnt11 more" *ngIf="unit && unit?.shop_name" title="{{unit.shop_name}}">{{unit ? unit.shop_name:''}}</span>
        </span>
        <span class="grid-50c">
            <strong class="txtcolr1 fnt11">UNIT</strong>
            <span  class="txtcolr2 fnt11" *ngIf="selected_unit_uids" (click)="copyToClipboard(selected_unit_uids)">{{selected_unit_uids}} <mat-icon title="Copy Floor ID"   svgIcon="copyfm" class="icon copy-icon"></mat-icon></span>
            <span *ngIf="copied && copied.length>0" class="txtcolr2 fnt11">&nbsp;{{copied}} &nbsp;</span>
        <span *ngIf="copied && copied.length==0" class="txtcolr3 fnt11">NA</span>
        </span>
        <span class="viewList" (click)="unitList()">Unit List

        </span>
        <span class="clear" (click)="clear()">Clear

        </span>
    </div>
    <hr class="line-fade" />
    <div class="grid-box">
        <div class="grid-50h">
            <span class="center lnk" (click)="drawPolygon('circle')" title="Drop circle">
                <mat-icon [ngClass]="{'active': isActive('circle')}" class="move-icn">add_circle_outline</mat-icon>
            </span>
            <span class="center lnk" (click)="drawPolygon('polygon')" title="Draw Polygon">
                <mat-icon [ngClass]="{'active': isActive('polygon')}" svgIcon="poly" class="move-icn"></mat-icon>
            </span>
        </div>
        <div class="grid-50h" (click)="copyUnit()">
            <div class="center">
                <mat-icon svgIcon="copyfm" class="icon"></mat-icon>
            </div>
            <div class="center">
                <span class="select">Copy</span>
            </div>
        </div>
        <div class="grid-50h" (click)="openGroupDialog()">
            <div class="center">
                <mat-icon svgIcon="pastefm" class="icon"></mat-icon>
            </div>
            <div class="center">
                <span class="select">Paste</span>
            </div>
        </div>
        <div class="grid-big-50h">
            <div class="center">
                <span class="txtcolr2"> <strong class="txtcolr1">Switch Floor</strong></span>
            </div>
            <div class="center">
                <mat-select class="btn1 btn-default drop ms" [value]="current_floor_info.uid" (selectionChange)="currentFloor($event)">
                    <mat-option class="ddList" *ngFor="let data of floors" value="{{data.uid}}">{{data.name}}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>
